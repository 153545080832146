import React, { useState } from 'react';
import Section from './Section';

const Info = ({activeSection}) => {
  // State hooks to control the visibility of each section
  const [showTickets, setshowTickets] = useState(false);
  const [GettingThere, setGettingThere] = useState(false);
  // const [ShowTimes, setShowTimes] = useState(false);
  const [Ages, setAges] = useState(false);
  // const [Alcohol, setAlcohol] = useState(false);
  // const [Campsites, setCampsites] = useState(false);
  // const [Toilets, setToilets] = useState(false);
  const [Entertainment, setEntertainment] = useState(false);
  const [OnBoardEarth, setOnBoardEarth] = useState(false);

  return (
    <Section activeSection={activeSection} id="info">
      <div className="info-container">
        <div className='header'>
          <h1>Info</h1>
        </div>

        <div className=' info-subheader'>
          <h4>Wastelands Festival is hosted across four of Glasgow's most iconic <a href="/venues" className='inline-link'>venues</a>. Offering a selection of gig and club spaces, locally-based crafts, and top Scottish bevvies.</h4>
        </div>

        <div className="dropdown-container">
          {/* Tickets Section */}
          <div className={`dropdown  ${showTickets ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setshowTickets(!showTickets)}>
              <h3>Tickets</h3>
              {/* Chevron */}
              <span className={`chevron ${showTickets ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {showTickets && (
              <div className="dropdown-body">
                <p className="courier">
                • Tickets are available on the website in the <a className="courier" href="/tickets">tickets</a> section. Once you purchase the ticket, take a screenshot of your confirmation and barcode or print the ticket to show when arriving to avoid any technical problems with poor signal. <br/><br/>
                • Tickets that have been purchased online cannot be refunded unless the event is cancelled. <br/><br/>
                {/* • Live-in vehicles tickets can be purchased in the ticket section along with car park tickets. We are aiming to discourage traffic at the festival and encourage car-pooling or public transport to promote green practices, so there will be a limited amount of car park passes available. It's first come first serve so be quick.<br/><br/> */}
                • There are no day tickets available for the first edition of this festival. Next year we will work on providing day tickets but this year only full weekend tickets are available.<br/><br/>
                • £3 of the ticket sales go towards Trees+, a project that plants trees, restores and preserves threatened ecosystems and supports communities on the front line of climate change. Wastelands Festival is raising funds to support Onboard:Earth's environmental restoration project to address unavoidable travel emissions. You can find more information on their website at <a href="https://onboard.earth/" className='courier' rel="noreferrer" target="_blank">Onboard:Earth</a> and in the Partners section. We appreciate each and every one of the contributions towards this cause.<br/><br/>
                • Please get in touch with us at enquiries@wastelandsfestival.co.uk if you have any other questions or problems with tickets, we will be happy to help.<br/><br/>
                • When you buy a ticket online at Resident Advisor, take your e-ticket to the box office located 
                  at BAaD (Barras Art and Design) and you will receive a wristband for the entire weekend. 
                  This wristband will grant you access to BAaD, Stereo, Slay and Room 2 for the whole 
                  weekend. Do not lose this wristband as it will not be replaced once it is given out.  <br/><br/>
                </p>
              </div>
            )}
          </div>

          {/* Getting There Section */}
          <div className={`dropdown  ${GettingThere ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setGettingThere(!GettingThere)}>
              <h3>Getting There - Frequently Asked Questions</h3>
              <span className={`chevron ${GettingThere ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {GettingThere && (
              <div className="dropdown-body">
                <p className="courier" >
                {/* • The festival address is Rule Valley Escapes, Gatehousecote, Bonchester Bridge in the Scottish Borders and is easily accessible via the B3657 road from Jedburgh.<br/><br/>
                • We are working to create sustainable travel options and reduce travel emissions alongside our partners. If you have the option to travel by public transport, or carpool, please take it.<br/><br/>
                • Edinburgh Airport and Newcastle Airport are within an hour and a half travel time from the festival site.<br/><br/>
                • The following trains to the Scottish Borders are as follows-<br/><br/>
                &emsp; • <a className="courier" href='https://www.scotrail.co.uk/inspiration-hub/great-places-to-visit/scottish-borders#'>Edinburgh to Tweedbank</a> - approx 55 minutes<br/><br/>
                &emsp; • <a className="courier" href='https://www.scotrail.co.uk/inspiration-hub/great-places-to-visit/scottish-borders#'>Aberdeen to Tweedbank</a> - approx 3 hours 50 minutes<br/><br/>
                &emsp; • <a className="courier" href='https://www.scotrail.co.uk/inspiration-hub/great-places-to-visit/scottish-borders#'>Glasgow to Tweedbank</a> - approx 2 hours 5 minutes<br/><br/>
                &emsp; • <a className="courier" href='https://www.scotrail.co.uk/inspiration-hub/great-places-to-visit/scottish-borders#'>Stirling to Tweedbank</a> - approx 2 hours 15 minutes<br/><br/><br/>
                 • From Tweedbank the No.68 Bus Service will take you to Jedburgh with one bus every hour.<br/><br/>
                 • From Jedburgh to the festival site it's an 8 minute drive, would highly recommend sharing a taxi or hitchhiking with fellow festival goers.<br/><br/> */}

                • The venues are all very close to Glasgow Central Station, Glasgow Queen Street train station and a short walk from Buchanan Bus Station. 
                <br/><br/>
                Train stations are a 10 minute walk to the venue, with Buchanan Bus Station being a 10 minute walk to the venues. 
                <br/><br/>
                Train tickets Edinburgh Waverley - Glasgow Queen Street = £8 - £10 for a single. Bus tickets Edinburgh - Glasgow Buchanan Bus Station = £4 - £8 for a single. 
                <br/><br/>
                Stereo is a 13 minute walk from Slay, Slay is a 10 minute walk from Room 2, and Stereo is a 6 minute walk from Room 2. The following addresses:
                <br/><br/> <br/><br/>
                &emsp; • BAaD - 54 Calton Entry, Glasgow G40 2SB (behind the Barrowlands Ballroom)
                <br/><br/>
                &emsp; • Stereo - 22 Renfield Ln, Glasgow G2 5AR 
                <br/><br/>
                &emsp; • SLAY - 24 Glassford St, Glasgow G1 1UL
                <br/><br/>
                &emsp; • Room 2 - 69 Nelson Mandela Pl, Glasgow G2 1QY 
                <br/><br/>  
                </p>
              </div>
            )}
          </div>

          {/* Times Section */}
          {/* <div className={`dropdown  ${ShowTimes ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setShowTimes(!ShowTimes)}>
              <h3>Show Times</h3>
              <span className={`chevron ${ShowTimes ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {ShowTimes && (
              <div className="dropdown-body">
                <p className="courier">
                • The festival site is open from 7pm Thursday and Friday - Sunday is open from 10am, with music starting at 12pm. <br/><br/>
                • Music will go on till 12am on Thursday and Sunday, and till 2am Friday and Saturday. As our first edition festival we understand that these times are deemed early finishes, and will work our best to create later nights come the second year. <br/><br/>
                • Monday morning curfew to leave the site is 12pm.<br/><br/>
                </p>
              </div>
            )}
          </div> */}
          {/* Ages Section */}
          <div className={`dropdown  ${Ages ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setAges(!Ages)}>
              <h3>Ages</h3>
              <span className={`chevron ${Ages ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {Ages && (
              <div className="dropdown-body">
                <p className="courier">
                • Unfortunately we cannot allow under 18's into Stereo, Slay or Room 2 for the event. 
                  However under 18's must be accompanied by an adult for the BAaD. 
                {/* • Wastelands Festival does not include child friendly programming or family camping, like with the Kid's areas this is something we would like to involve in future editions.<br/><br/> */}
                </p>
              </div>
            )}
          </div>
          {/* Alcohol Section */}
          {/* <div className={`dropdown  ${Alcohol ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setAlcohol(!Alcohol)}>
              <h3>Alcohol</h3>
              <span className={`chevron ${Alcohol ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {Alcohol && (
              <div className="dropdown-body">
                <p className="courier">
                • The festival allows you to bring your own alcohol, however there is a strict NO GLASS policy on site, and any glass that has been brought to the field will have to be decanted or is at risk of being confiscated.<br/><br/>
                • The campsite is BYOB however only alcohol bought from the bars will be allowed in the arena.<br/><br/>
                </p>
              </div>
            )}
          </div> */}
          {/* Campsites Section */}
          {/* <div className={`dropdown  ${Campsites ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setCampsites(!Campsites)}>
              <h3>Campsites</h3>
              <span className={`chevron ${Campsites ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {Campsites && (
              <div className="dropdown-body">
                <p className="courier">
                • Strictly NO FIRES on the premises. If you are caught breaking this rule you risk having your ticket voided and will be asked to leave. <br/><br/>
                • All tents must be brought away with you and all litter put to the recycling bins provided. At Wastelands we aim to follow a strict LEAVE NO TRACE rule. <br/><br/>
                • There will be toilets in the campsite but please bring a back-up toilet roll. You never know if you might need it.<br/><br/>
                • We will have a clearly signed waterpoint in the campsite, but also bring water with you in a refillable bottle to avoid litter.<br/><br/>
                • There will be NO CHARGING AREAS so make sure you bring power banks with plenty of charge. This is something we will try to introduce in the future Wastelands.<br/><br/>
                • There are NO SHOWERS in general camping. Embrace the dirt. <br/><br/>
                </p>
              </div>
            )}
          </div> */}
          {/* Toilets Section */}
          {/* <div className={`dropdown  ${Toilets ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setToilets(!Toilets)}>
              <h3>Toilets</h3>
              <span className={`chevron ${Toilets ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {Toilets && (
              <div className="dropdown-body">
                <p className="courier">
                • We will have easy to spot, well signposted toilets on site with hand cleaner, toilet roll and tampons.<br/><br/>
                • There will be accessible toilets on site also for anybody with additional needs.<br/><br/>
                • Please bring a roll of toilet paper in case of emergencies in general camping.<br/><br/>
                • We strive to keep the toilets as clean as possible for other festival goers. Please treat the toilet as if you were the next person going in.<br/><br/>
                </p>
              </div>
            )}
          </div> */}
          {/* Entertainment Section */}
          <div className={`dropdown  ${Entertainment ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setEntertainment(!Entertainment)}>
              <h3>Bars, Food and Entertainment</h3>
              <span className={`chevron ${Entertainment ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {Entertainment && (
              <div className="dropdown-body courier">
                <p className="courier">
                • There will be bars in each venue supplying local brewed beverages. There will be alcohol free available at these bars.<br/><br/>
                • At BAaD there will be market stalls by local Glasgow based artists out on the streets to sell 
                  their wares. Please do take some time to have a look at all these stalls and support the 
                  wonderful Glasgow DIY scene. We are in partnership with Thistly Cross cider and Tempest 
                  Brewery to deliver the tastiest beverages this side of planet eart<br/><br/>
                {/* • There will be a large selection of Food Trucks & Vendors available across Wastelands that will cater to all diets; vegan, gluten free etc. Please make known any allergies that you have to any vendor when making a purchase. <br/><br/> */}
                {/* • There will be NO ATMS at Wastelands Festival so bring enough cash to see you through the weekend.<br/><br/> */}
                {/* • All food vendors, bars and marketplaces will adhere to sustainable practices and provide recyclable utensils, following green practice guidelines.<br/><br/> */}
                {/* • There will be various marketplaces supplying all kinds of interesting and cool things from local artists and independent businesses, thriving to support thescene. You will find these Markets in the main field of Wastelands.<br/><br/> */}
                {/* • We will host a wide variety of workshops to encourage learning, and to entertain. You will find these scattered across Wastelands, and all are welcome to anyone.<br/><br/> */}
                {/* • There are three stages of music for the weekend, two stages in the main field, and one stage nestled into the woodlands. Wastelands hosts a completely diverse wealth of different music and genres, so you will find something for everyone. Whether it be some inspiring jazz, folk or trad beside the loch, a dance in the bouncing woodlands under the canopies, or letting off some steam in the pit, you will find what you need here in the Wastelands.<br/><br/> */}
                </p>
              </div>
            )}
          </div>
          <div className={`dropdown dropdown-last  ${OnBoardEarth ? 'dropdown-open' : ''}`}>
            <div className="dropdown-header" onClick={() => setOnBoardEarth(!OnBoardEarth)}>
              <h3>OnBoard:Earth</h3>
              <span className={`chevron ${OnBoardEarth ? 'open' : ''}`}>&#x25B6;</span>
            </div>
            {OnBoardEarth && (
              <div className="dropdown-body courier">
                <p className="courier">
                • onboard:earthis a live events industry response to the climate crisis - a community ofevents, festivals, suppliers, artists and music companies taking action to reduce travelimpactsby moving low-carbon travel into the heart of live events culture andraisingfunds forenvironmental restoration.<br/><br/>
                • Since 2015,onboard:earthhas worked with over 160 event industry members, providing resources, tools, advice and inspiration to reduce travel emissions, and channelling over £659,000 from event-goers to environmental restoration and climateaction; funding community-led, renewable energy generation and ecosystem protection to protect biodiversity for communities on the front line of climate change. <br/><br/>
                • Visit the <a href="https://onboard.earth/" target='_blank' rel="noreferrer" className='courier'>onboard:earth website</a> to find out more.<br/><br/>
               
                </p>
              </div>
            )}
          </div>
          <a className='link-highlight' href='/tickets'>TICKETS</a>
          <h2 className='info-footer'>SEE YOU IN THE WASTELANDS</h2>
        </div>
      </div>
    </Section>
  );
};

export default Info;
