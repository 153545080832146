import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; // For dynamic meta tags

import '../css/lineup.css';
import mandidextrous_img from '../img/artist_img/mandidextrous.png'
// import wand_img from '../img/artist_img/wand.png'
import smote_img from '../img/artist_img/smote_new.jpg'
// import junior_brother_img from '../img/artist_img/junior brother.png'
import pippa_bundell_img from '../img/artist_img/pippa_bundell.png'
// import clt_img from '../img/artist_img/clt_drp.png'
import yard_img from '../img/artist_img/YARD.jpeg'
import kute_img from '../img/artist_img/kute.png'
import magoo_img from '../img/artist_img/MRSMAGOO.jpeg'
import gaia_img from '../img/artist_img/gaia.png'
import morayleisurecentre_img from '../img/artist_img/moray_leisure_centre.png'
import pc_world_img from '../img/artist_img/pc_world.png'
import craig_john_img from '../img/artist_img/cjd_new.jpg'
import going_off_img from '../img/artist_img/xiao_new.jpeg'
import hometown_img from '../img/artist_img/Hometown.jpg'
import coffin_mulch_img from '../img/artist_img/coffin_mulch.png'
// import bass_injection_img from '../img/artist_img/bass_injection.png'
import bass_injection_img from '../img/artist_img/bassinject_new.jpeg'
import diall_img from '../img/artist_img/diall.png'
import gotne_img from '../img/artist_img/gotne_new.jpg'
import bleaks_img from '../img/artist_img/bleaks.png'
import spit_img from '../img/artist_img/spit.png'
import vibrator_img from '../img/artist_img/vibrator.png'
import underthunder_img from '../img/artist_img/underthunder.png'
import pelk_img from '../img/artist_img/pelk.png'
// import ego_img from '../img/artist_img/ego.png'
// import soapbox_img from '../img/artist_img/soapbox.png'
import tues_img from '../img/artist_img/tues.png'
import acevision_img from '../img/artist_img/acevision.png'
import xiao_img from '../img/artist_img/xiao.jpg'
import ecoli_img from '../img/artist_img/ecoli.png'
import narna_img from '../img/artist_img/NARNA.jpg'
import euphonique_img from '../img/artist_img/Euphonique.jpg'
import klumsy_img from '../img/artist_img/klumzy.jpg'
import moral_bombing_img from '../img/artist_img/moral_bombing.jpg'
import ultras_img from '../img/artist_img/ultras.jpg'


import mandidextrous_img_webp from '../img/artist_img/mandidextrous.png'
// import wand_img_webp from '../img/artist_img_webp/wand.webp'
// import smote_img_webp from '../img/artist_img_webp/smote.webp'
// import junior_brother_img_webp from '../img/artist_img_webp/junior brother.webp'
import pippa_bundell_img_webp from '../img/artist_img_webp/pippa_bundell.webp'
// import clt_img_webp from '../img/artist_img_webp/clt_drp.webp'
import yard_img_webp from '../img/artist_img_webp/YARD.webp'
import kute_img_webp from '../img/artist_img_webp/kute.webp'
import magoo_img_webp from '../img/artist_img_webp/MRSMAGOO.webp'
import gaia_img_webp from '../img/artist_img_webp/gaia.webp'
import morayleisurecentre_img_webp from '../img/artist_img_webp/moray_leisure_centre.webp'
import pc_world_img_webp from '../img/artist_img_webp/pc_world.webp'
// import craig_john_img_webp from '../img/artist_img_webp/craig_john.webp'
// import going_off_img_webp from '../img/artist_img_webp/going_off.webp'
import hometown_img_webp from '../img/artist_img_webp/Hometown.webp'
import coffin_mulch_img_webp from '../img/artist_img_webp/coffin_mulch.webp'
// import bass_injection_img_webp from '../img/artist_img_webp/bass_injection.webp'
import diall_img_webp from '../img/artist_img_webp/diall.webp'
// import gotne_img_webp from '../img/artist_img_webp/gotne.webp'
import bleaks_img_webp from '../img/artist_img_webp/bleaks.webp'
import spit_img_webp from '../img/artist_img_webp/spit.webp'
import vibrator_img_webp from '../img/artist_img_webp/vibrator.webp'
import underthunder_img_webp from '../img/artist_img_webp/underthunder.webp'
import pelk_img_webp from '../img/artist_img_webp/pelk.webp'
// import ego_img_webp from '../img/artist_img_webp/ego.webp'
// import soapbox_img_webp from '../img/artist_img_webp/soapbox.webp'
import tues_img_webp from '../img/artist_img_webp/tues.webp'
import acevision_img_webp from '../img/artist_img_webp/acevision.webp'
import xiao_img_webp from '../img/artist_img_webp/xiao.webp'
import ecoli_img_webp from '../img/artist_img_webp/ecoli.webp'
import narna_img_webp from '../img/artist_img_webp/NARNA.webp'
import euphonique_img_webp from '../img/artist_img_webp/Euphonique.webp'
import klumsy_img_webp from '../img/artist_img_webp/KLUMZYKHEMIST.webp'
import moral_bombing_img_webp from '../img/artist_img_webp/moral_bombing.webp'
import ultras_img_webp from '../img/artist_img_webp/ultras.webp'

import aku_img_webp from '../img/artist_img_webp/aku.webp'
import aku_img from '../img/artist_img/aku.jpg'
import algu_img_webp from '../img/artist_img_webp/algu.webp'
import algu_img from '../img/artist_img/algu.jpg'
import baguette_img_webp from '../img/artist_img_webp/baguette.webp'
import baguette_img from '../img/artist_img/baguette.jpg'
import benny_img_webp from '../img/artist_img_webp/benny.webp'
import benny_img from '../img/artist_img/benny.jpg'
import bessa_img_webp from '../img/artist_img_webp/bessa.webp'
import bessa_img from '../img/artist_img/bessa.jpg'
import blood_img_webp from '../img/artist_img_webp/blood.webp'
import blood_img from '../img/artist_img/blood.jpg'
import blossom_img_webp from '../img/artist_img_webp/blossom.webp'
import blossom_img from '../img/artist_img/blossom.jpg'
import clobber_img_webp from '../img/artist_img_webp/clobber.webp'
import clobber_img from '../img/artist_img/clobber.jpg'
// import corto_img_webp from '../img/artist_img_webp/corto.webp'
// import corto_img from '../img/artist_img/corto.jpg'
// import divine_img_webp from '../img/artist_img_webp/divine.webp'
// import divine_img from '../img/artist_img/divine.jpg'
import doss_img_webp from '../img/artist_img_webp/doss.webp'
import doss_img from '../img/artist_img/doss.jpg'
import empire_img_webp from '../img/artist_img_webp/empire.webp'
import empire_img from '../img/artist_img/empire.jpg'
import feena_img_webp from '../img/artist_img_webp/feena.webp'
import feena_img from '../img/artist_img/feena.jpg'
import fullpelt_img_webp from '../img/artist_img_webp/fullpelt.webp'
import fullpelt_img from '../img/artist_img/fullpelt.jpg'
import gimic_img_webp from '../img/artist_img_webp/gimic.webp'
import gimic_img from '../img/artist_img/gimic.jpg'
import jrbro_img_webp from '../img/artist_img_webp/jrbro.webp'
import jrbro_img from '../img/artist_img/jrbro.jpg'
import kitti_img_webp from '../img/artist_img_webp/kitti.webp'
import kitti_img from '../img/artist_img/kitti.jpg'
import moni_img_webp from '../img/artist_img_webp/moni.webp'
import moni_img from '../img/artist_img/moni.jpg'
import ochre_img_webp from '../img/artist_img_webp/ochre.webp'
import ochre_img from '../img/artist_img/ochre.jpg'
import overpower_img_webp from '../img/artist_img_webp/overpower.webp'
import overpower_img from '../img/artist_img/overpower.jpg'
import raivo_img_webp from '../img/artist_img_webp/raivo.webp'
import raivo_img from '../img/artist_img/raivo.jpg'
import roki_img_webp from '../img/artist_img_webp/roki.webp'
import roki_img from '../img/artist_img/roki.jpg'
import um_img_webp from '../img/artist_img_webp/um.webp'
import um_img from '../img/artist_img/um.jpg'
// import dannsa_img_webp from '../img/artist_img_webp/dannsa.webp'
// import dannsa_img from '../img/artist_img/dannsa.jpg'
// import brassholes_img_webp from '../img/artist_img_webp/brassholes.webp'
// import brassholes_img from '../img/artist_img/brassholes.jpg'
import miira_img_webp from '../img/artist_img_webp/miira.webp'
import miira_img from '../img/artist_img/miira.jpg'
import link_img_webp from '../img/artist_img_webp/link.webp'
import link_img from '../img/artist_img/link.jpg'
import wreaking_img_webp from '../img/artist_img_webp/wreaking.webp'
import wreaking_img from '../img/artist_img/wreaking.jpg'
import scoop_img_webp from '../img/artist_img_webp/scoop.webp'
import scoop_img from '../img/artist_img/scoop.jpg'
import hever_img_webp from '../img/artist_img_webp/hever.webp'
import hever_img from '../img/artist_img/hever.jpg'



import Blobs from '../Components/Blobs';

// Sample Artist Data
const artistData = [
//   {
//     id: 0,
//     name: 'CORTO.ALTO',
//     image: corto_img,
//     webpImage: corto_img_webp,
//     description: `Fast-emerging Scottish composer, producer and multi-instrumentalist corto.alto has been
// described as a traditional jazz head raised in the age of the internet. The moniker of Glasgow-based Liam Shortall, corto.alto brings a fresh perspective to a heady mix of intuitive improvisation, electronic production, broken beat bounce and bass-heavy dub. His debut album ‘Bad With Names’ out in Oct 2023 was shortlisted for the Mercury Prize 2024. It’s an honest and iconoclastic work, challenging the boundaries of contemporary jazz.
// Championed by Gilles Peterson, Jamie Cullum, BBC 6 Music, KCRW, Jazzwise, Fip radio in France and RRR in Australia to name a few, as well as the editorial teams at Spotify, Apple Music and Bandcamp, ‘Bad With Names’ brings together some of the UK’s finest young players, including pianist Fergus McCreadie, trumpet player James Copus, drummer Graham Costello, with the flair of a string quartet, taking the corto.alto sound in a new direction. It is also the most complete and sophisticated statement of the corto.alto sound to date– a riptide demonstration of his multi-instrumental abilities, punchy improvisation and subtle electronic production that has stitched it all back together again.
// `,
//     embedType: 'spotify',
//     embedUrl: 'https://open.spotify.com/embed/artist/5Kd7e5lauV4CDdTHm5uiJH?utm_source=generator',
//   },
  {
    id: 1,
    name: 'MANDIDEXTROUS',
    image: mandidextrous_img, 
    webpImage: mandidextrous_img_webp,
    description: 'For over 15 years, Mandidextrous has been an instrumental figure in the UK/European underground music industry. Known for their seamless fusion of D&B and Techno, they captivate audiences with high-energy performances and a nostalgic rave sound.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/0oVDzp5DK2caqb6FuL2mhp?utm_source=generator', // Spotify URL
  },
  {
    id: 47,
    name: 'JUNIOR BROTHER',
    image: jrbro_img,
    webpImage: jrbro_img_webp,
    description: `An idiosyncratic, challenging and richly lyrical singer/ songwriter, Junior Brother is the pseudonym of Co. Kerry, Ireland singer Ronan Kealy. Chosen as The Irish Times’ Best Irish Act of 2019 and nominated for the 2019 Choice Music Prize for Album of the Year, Junior Brother has built a rabid following thanks to unmissable live shows, and music both excitingly forward-looking and anciently evocative. His strange stories unfold with reckless abandon upon a distinctive guitar and foot tambourine accompaniment, influenced as much by the avant-garde as music from the Middle Ages and his home place in rural Ireland.`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2CdmJGEGEkzPZ966NCmtKz?utm_source=generator',
  },
  // {
  //   id: 2,
  //   name: 'WAND',
  //   image: wand_img, 
  //   webpImage: wand_img_webp,
  //   description: 'Wand is an American psychedelic rock band from Los Angeles, blending crunchy guitar tones with floaty vocals. Known for their prolific output and unique sound, they continue to push boundaries in the psychedelic rock scene.',
  //   embedType: 'spotify',
  //   embedUrl: 'https://open.spotify.com/embed/artist/53by9P3tpXOW1diWCI6MNu?utm_source=generator', // Spotify URL
  // },
  {
    id: 45,
    name: 'BENNY PAGE',
    image: benny_img,
    webpImage: benny_img_webp,
    description: `Blasting onto the scene with his 2006 anthem ‘Turn Down The Lights’ Benny then released a slew of seminal ragga jungle cuts, as well as numerous well received productions across other areas of the dance music spectrum. Having cut his teeth on the Digital Soundboy imprint, he has gone on to collaborate with some of the most exciting producers and vocalists in the industry, from MC Spyda, Topcat and Sweetie Irie to Jessie J, Tinnie Tempah, Misha B, Arlisa & Nas, as well as taking his blazing brand of dubwise DnB to audiences across the globe. His remix collaboration with Congo Natty on “UK Allstars” remains an enduring modern classic of the UK underground.
Benny has been a fixture at Boomtown Festival for the best part of a decade and is a central figure in the Jungle Cakes collective of artists. The birth of his label Dubshotta in 2016, with a debut EP from Benny himself, saw the artist receive a whole lot of love from the likes of DJ Target and 1 Xtra, and he’s been ever-present on the airwaves ever since.
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/602tBNQHNIZL6tsB2RHAV1?utm_source=generator',
  },
//   {
//     id: 87,
//     name: 'TOM MCGUIRE AND THE BRASSHOLES',
//     image: brassholes_img,
//     webpImage: brassholes_img_webp,
//     description: `Tom McGuire & the Brassholes are a funk soul powerhouse from Glasgow. Combining rich arrangements with meticulous songcraft and untouchable in live performance, the band is carving a niche of their own. In January 2023, the band sold out their debut appearance at the legendary Barrowlands in Glasgow with the release of their highly anticipated second album “Stay Rad”. The band’s music is full of vitality, the sound of a new niche being cut, classic pop sensibility with high musicianship values and rewarding arrangement and rich production. At the root is soulful song, telling stories and sharing experiences of joy, turmoil, loss, celebration, sadness, happiness, confidence, frailty. All told hyper distilled and delivered in finely honed funk pop parcels of aesthetic joy. Life is confusing and hard, but music is fun and great, so let’s talk about it whilst at one and the same time, getting down.
// `,
//     embedType: 'spotify',
//     embedUrl: 'https://open.spotify.com/embed/artist/7JnJgTo8cCtAQmtC0cJyjp?utm_source=generator',
//   },
  // {
  //   id: 85,
  //   name: 'AN DANNSA DUB',
  //   image: dannsa_img,
  //   webpImage: dannsa_img_webp,
  //   description: `An Dannsa Dub (The Dub Dance in Gaelic) is the brainchild of dub vocalist & producer Tom Spirals and Scottish traditional musician Euan McLaughlin formed in 2020. The 6 piece live band fuses the mystical energy and instruments of traditionalScottish music and Gaelic song with the heavy, meditative,driving basslines digital dub music.Music is one big conversation. And few bands live at the heart of it like An Dannsa Dub.Born from a love of traditional Scottish folk, dub reggae and dance music, together they summon the joyous, communal energy of acèilidh and a sound system session.Their name comes from the Gaelic for “The Dub Dance”. Theirsound celebrates the ancient Gaelic and West African rhythms that influenced reggae, which in turn, via sound system culture and dub's pioneering remixes, produced modern dance music. Their live shows and recordings are a seamless, pulsating fusion of all this and more.`,
  //   embedType: 'spotify',
  //   embedUrl: 'https://open.spotify.com/embed/artist/5qVrRD2al9wFR0OcJa1VcH?utm_source=generator',
  // },
  {
    id: 43,
    name: 'DOSS',
    image: doss_img,
    webpImage: doss_img_webp,
    description: `Doss, the bold electronic post-punk trio from Glasgow—featuring Sorley Mackay, Mark Black, and Chilton Fawcett—has carved out a distinctive sound, blending hard-hitting drum beats, trudging basslines, and sharp, tongue-in-cheek lyricism. Initially a solo project by Sorley Mackay, Doss was born in a modest bedroom studio, creating electrifying tracks with minimal gear, pairing driving rhythms with provocative vocals and witty wordplay. The band explores themes like gentrification, addiction, zero-hours contracts, and generational trauma, infusing their music with a strong Scottish identity, delivered through their unmistakable Glaswegian dialect. Renowned for their high-energy live performances, Doss has headlined sold-out gigs across major UK and EU cities to widespread acclaim.`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/3csu8AxiaJammKmr2EPtSe?utm_source=generator',
  },
  // {
  //   id: 6,
  //   name: 'CLT DRP',
  //   image: clt_img, 
  //   webpImage: clt_img_webp,
  //   description: "CLT DRP (pronounced 'CLIT DRIP') has been turning heads with their unique fusion of punk, electronica, and heavy pop. Their live performances are uncompromising and forceful, creating a sound that's uniquely their own.",
  //   embedType: 'spotify',
  //   embedUrl: 'https://open.spotify.com/embed/artist/0y7VykZdOB8wIsAQfr2S4M?utm_source=generator', // Spotify URL
  // },

  {
    id: 32,
    name: 'XIAO',
    image: going_off_img,
    description: "Formed in 2019, Xiao is a hardcore band from Sweden that can go from the fast brutal speed of power violence and D-beat one moment, and slower and measured the next. XIAO is a musical onslaught, keeping the audience on a knife edge for the whole set, described as an ‘unrelenting assault on the senses’, this band doesn’t hold back, come and see it for yourself.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/7p7bBRBk6IU0X0lqIGj0fJ?utm_source=generator',
  },
  {
    id: 33,
    name: 'E-COLI',
    image: ecoli_img,
    webpImage: ecoli_img_webp,
    description: "E-coli has refined his sound to be a heavy blend of Frenchcore and Hardtek, with balkan, gypsy and world melodies. It is uplifting and fun music, always with hard kicks and heavy bass. He is a core member of Amen4Tekno, and has also had releases on Peacock Records, Undergroundtekno, Frenchcore S’il Vous Plait, Audiogenic, Jigsore Sound, Irritant and many more. He also has a live project with Ed Cox (Ed Cox & E-Coli) blending their two styles and featuring live accordion and keys, often with many guest musicians to make it a full show.In 2021 he continues to push his Frenchcore and Hard Tek sound to have heavier kicks and more brass and melodies, ready to destroy dancefloors.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2MPy1k969lurKeHRzB5ljY?utm_source=generator',
  },
  {
    id: 61,
    name: 'KITTI',
    image: kitti_img,
    webpImage: kitti_img_webp,
    description: `kitti’s voice evokes deep emotion and touches the heart of the listener. She has a voice that has the power to move people to tears, to uplift their spirits, and to transport them to another world, and with this she soon
began to attract the attention of like-minded musicians, who would quickly become her
core band, she eventually settled into her own sound and writing style, taking
massive influence from great artists like Chaka Khan & Rufus, Aretha Franklin and Betty
Davis. kitti gained 2 awards for her work in 2020 - that of “Best Female Breakthrough” at the
Scottish Music Awards and “Best Jazz Vocalist” at the Scottish Jazz Awards, which she
won once again in 2022. She has also supported the iconic Mavis Staples and Van
Morrison in 2022 - and Rod Stewart at his Edinburgh Castle shows in 2023. kitti was
also a part of the Scottish National Jazz Orchestra’s “Nu-Age Sounds” series that toured
Scotland in March ‘24.
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/5CZKJlImRAqIdAwRTFVL6o?utm_source=generator',
  },
  {
    id: 7,
    name: 'YARD',
    image: yard_img, 
    webpImage: yard_img_webp,
    description: 'YARD, a gripping electro-noise band from Dublin, delivers an intense sensory experience. Bridging the gap between techno and noise rock, their eerie performances leave audiences sweaty, energized, and strangely unnerved.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/36aHdS6WBzKMBA9pt1FYFS?utm_source=generator', // Spotify URL
  },
  {
    id: 18,
    name: 'Diall',
    image: diall_img,
    webpImage: diall_img_webp,
    description: "Diall delivers bitter, sarcastic, and demented hardcore from Newcastle. Their music is loaded with ominous riffs, bestial growls, and powerful stomps, leaving their mark on the hardcore scene.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/293YTaAByzdUouPo8L34WC?utm_source=generator',
  },
  {
    id: 4,
    name: 'SMOTE',
    image: smote_img, 
    description: 'Smote brings a raw energy to their music with distinctive drumming, guitar feedback, and droning open-tuned guitars. They blend elements of ancient and otherworldly music with modern, meditative, DIY aesthetics, gaining a reputation as a powerful live band.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/343ATWFlCVF1Z3PyDwFEXp?utm_source=generator', // Spotify URL
  },
  {
    id: 5,
    name: 'PIPPA BLUNDELL',
    image: pippa_bundell_img, 
    webpImage: pippa_bundell_img_webp,
    description: 'Pippa Blundell is a Glasgow-grown songstress whose raw, heartfelt lyrics and gentle guitar work create an intense, modern folk sound. Influenced by classical vocal roots and urban storytelling, she wraps listeners in a blanket of harmonies.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/7Hi97v7aZnyJhysmVwOIWt?utm_source=generator', // Spotify URL
  },
  {
    id: 9,
    name: 'MRS MAGOO',
    image: magoo_img, 
    webpImage: magoo_img_webp,
    description: 'Mrs Magoo is an award-winning UK Jungle DJ, known for her savage skills behind the controls. Her collaborations with pioneers like Nicky Blackmarket and Kenny Ken have earned her a prominent spot in the Jungle music scene.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/01GlXWZ8D6Ql80ERLMn7V7?utm_source=generator', // Spotify URL
  },
  // {
  //   id: 27,
  //   name: 'SOAPBOX',
  //   image: soapbox_img,
  //   webpImage: soapbox_img_webp,
  //   description: "Formed in 2022, Soapbox has built a small but loyal following in Glasgow. Their live shows are raucous, wild, and aggressive, making them one of the city's most exciting new acts.",
  //   embedType: 'spotify',
  //   embedUrl: 'https://open.spotify.com/embed/artist/5Uz6P4BCpwuOjnznmreqFO?utm_source=generator',
  // },
  {
    id: 63,
    name: 'AKU!',
    image: aku_img,
    webpImage: aku_img_webp,
    description: `A hard-boiled trio of Scottish musicians, AKU! always
make a powerful statement when the perform. They
generate their sound with saxophone and trombone
trading between the roles of a rhythm section and a
frontline with the use of effects on top of highly intense
drums. Fusing together the genres of noise-rock,
afrobeat and hip-hop AKU! are thriving to follow in the
footsteps of artists including Death Grips, Sons of
Kemet, Fela Kuti and The Thing. AKU! released their debut EP ‘Blind Fury’ on emerging
Bristol label Worm Discs in November 2020, now
available on Bandcamp and all major streaming
services.
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4YL5YNoHnTi00OL2cXpUQM?utm_source=generator',
  },
  {
    id: 8,
    name: 'KUTE',
    image: kute_img, 
    webpImage: kute_img_webp,
    description: 'Kute is a hardcore band from Glasgow, known for dissonant, fast guitars, shouted vocals, and powerful, ferocious live shows. They have been dominating the local hardcore scene and continue to evolve.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4KEAIKwqrsvEwdprMQxb54?utm_source=generator', // Spotify URL
  },
  {
    id: 37,
    name: 'EUPHONIQUE ',
    image: euphonique_img,
    webpImage: euphonique_img_webp,
    description: "With beats on respected D&B labels such as Hospital, Born On Road, Jungle Cakes, Dread Recordings, Ruffneck Ting and her own imprint Subwoofah Records, Euphonique’s high energy style has been part of the landscape since she emerged in 08 and has led to heavy plays on Radio 1 and 1Xtra; nominations at the We Love Jungle Awards and Drum & Bass Awards; collabs with Bou, Selecta J-Man, Gray, Redders, Aries, DJ Hybrid, Kelvin 373, Madrush MC, Bam Bam, MC Frost and performances at iconic dances. Glastonbury to Boomtown, Warehouse Project to Hospitality, E1 to Fabric, UK to Europe to Asia and beyond; Euphonique has taken her art around the world and across the airwaves. Broadcaster since ‘09, she currently hosts a show with jungle pioneer Dazee on SWU.FM.",
    embedType: 'none',
    embedUrl: '',
  },
  
  
  {
    id: 10,
    name: 'GAïA',
    image: gaia_img, 
    webpImage: gaia_img_webp,
    description: 'Gaïa is a Scottish-based jazz and neo-soul artist from France, blending beautiful melodic vocals with R&B, jazz, and soul influences. Her music has become a vibrant force in the Glasgow jazz scene.',
    embedType: 'spotify',
    embedUrl: '', // No Spotify link available
  },
  {
    id: 11,
    name: 'MORAY LEISURE CENTRE',
    image: morayleisurecentre_img, 
    webpImage: morayleisurecentre_img_webp,
    description: "Clyde Built Radio resident and EHFM host, Moray Leisure Centre is one of Scotland's finest selectors. Specialising in high-tempo techno, electro, and jungle, her sets are a must-experience in Scotland's dance scene.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1793868426&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true', // SoundCloud URL
  },
  {
    id: 12,
    name: 'CRAIG JOHN DAVIDSON',
    image: craig_john_img, 
    description: "raig John Davidson is a Scottish singer / songwriter / producer signed to Kiss My Kunst Records (Uk) Recording and releasing music since the early 2000's. Craig has gained a loyal fanbase through touring the U.K. Europe as well as the United States. With his remarkable finger style playing ability Craig mixes sounds of indie acoustic with his root inspiration of folk blues grunge and beyond.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/5TbeV4cUmpF1DYuNzCdSJ2?utm_source=generator', // SoundCloud URL
  },
  {
    id: 13,
    name: 'PC WORLD',
    image: pc_world_img,
    webpImage: pc_world_img_webp,
    description: "The South London duo, PC World, blends EBM and synth-punk into a snarky industrial soundscape, perfect for club rebels and mutant punks alike. The group is a sonic surrender to future shock, sparking a primal desire for more.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2oEpLj5O6CPZeMVe8k2ruN?utm_source=generator',
  },
  {
    id: 14,
    name: 'GOING OFF',
    image: ultras_img,
    webpImage: ultras_img_webp,
    description: "Going Off sets themselves apart from other Mancunian HC bands with their brutal guitar tone and rabid vocal style, blending Crust, D-beat, Beatdown Hardcore, and Metalcore/Crossover. For fans of Discharge and Amebix.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2xCCFcGLx1oUlS1AMtfBo9?utm_source=generator',
  },
  {
    id: 15,
    name: 'Hometown Soundsystem',
    image: hometown_img,
    webpImage: hometown_img_webp,
    description: "Hometown Promotion Soundsystem is a Glasgow-based collective specializing in reggae, roots, dancehall, ska, and rocksteady. Their mission is to use reggae music to foster community-focused initiatives.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1829361459&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 16,
    name: 'Coffin Mulch',
    image: coffin_mulch_img,
    webpImage: coffin_mulch_img_webp,
    description: "Coffin Mulch, an old-school death metal band from Glasgow, brings some of the heaviest sounds from the Central Belt with their savage, raw energy.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/75wcm11JP5KtuLmJJUFxZl?utm_source=generator',
  },
  {
    id: 17,
    name: 'Bass Injection',
    image: bass_injection_img,
    description: "Operating out of Scotland, Bass Injection specializes in underground bass music, bringing savagely heavy D&B and fast bassline beats to Glasgow's club scene.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1525173604&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  
  {
    id: 20,
    name: 'Girls Of The Northern Embassy',
    image: gotne_img,
    description: "Girls Of The Northern Embassy are bringing fast-paced D&B, Jungle, and Hardstyle mixes to the stage. DJs Super Han, Sad Spit Sally, and Kayteebee are set to light up the event.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1442293888&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 21,
    name: 'Bleaks',
    image: bleaks_img,
    webpImage: bleaks_img_webp,
    description: "Glasgow's Bleaks are fast hardcore icons, blending DIY punk ethics with ferocious energy. They have made a name for themselves in Scotland's hardcore scene.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4yrgtOL2Apk0ulZKZygDZt?utm_source=generator',
  },
  {
    id: 22,
    name: 'SPIT',
    image: spit_img,
    webpImage: spit_img_webp,
    description: "Newcastle's SPIT delivers fast, vicious hardcore. Their music is angry and relentless, and they are quickly making a name for themselves in the UK hardcore scene.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2DGdQY5dW6I2kK5IlszMhg?utm_source=generator',
  },
  {
    id: 23,
    name: 'Vibrator',
    image: vibrator_img,
    webpImage: vibrator_img_webp,
    description: "Vibrator from Aberdeen blends surf rock with punk, pulling influences from bands like Dead Kennedys and Black Flag. Expect smooth tones mixed with high-energy, angst-driven performances.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/album/143oezPdj6fcUN51mwDn5p?utm_source=generator',
  },
  {
    id: 24,
    name: '[UNDERTHUNDER]',
    image: underthunder_img,
    webpImage: underthunder_img_webp,
    description: "[underthunder] is a DJ, poet and translator with a penchant for surprise, known to rapidly switch up tempos, genres and percussion in the club. She came to music through poetry, exploring its relationship with dance music. She first started to mix in order to make words rest 'underneath' abrasive sounds – hence her DJ name. She is the co-founder of Phlox, a collective of femme & non-binary creatives exploring the multisensorial in club space. Expect wild industrial sounds, breaks, fast beats and otherworldly sounds. ",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1795435048&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 25,
    name: 'PELK',
    image: pelk_img,
    webpImage: pelk_img_webp,
    description: "Pelk, a DJ and producer from the North East, is heavily influenced by deconstructed club music. His sound is a warped and decimated percussive journey honed across UK and European dancefloors.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1288472293&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  // {
  //   id: 26,
  //   name: 'EGO',
  //   image: ego_img,
  //   webpImage: ego_img_webp,
  //   description: "Ego is a hardcore five-piece from Leeds, bringing heavy grooves and savage vocals to the UK hardcore scene. Their funk-laced riffs make them stand out in the heavy hardcore genre.",
  //   embedType: 'spotify',
  //   embedUrl: 'https://open.spotify.com/embed/artist/4wMMAQV23dr2z8HveXw4ER?utm_source=generator',
  // },
  
  {
    id: 29,
    name: 'TUES.',
    image: tues_img,
    webpImage: tues_img_webp,
    description: "tues. exist somewhere between the city and a grey beach. It’s rap (sort of). Following two EP’s, tues. recently headlined a sold out SWG3 Poetry Club and gained radio play on Rinse FM. The track 'Skin from ‘2235 pt. one’ received excellent reviews on the podcast Everybody Wants to Play the Hits. ‘Hill’ from the same EP was described on Foundation FM as “one of the most exciting rap songs [they] have heard in a long, long time.”",
    embedType: 'none',
    embedUrl: '',
  },
  {
    id: 31,
    name: 'ACEVISION',
    image: acevision_img,
    webpImage: acevision_img_webp,
    description: "A Scottish-Malawian spoken word poet, actor, and active rapper/musician who creates and releases their own music and pieces independently, ACE's thoughtful mastery of words brings a considered voice to the canvas of the group, literally painting pictures with a thousand words, as well as part of several local bands and collectives in and around Glasgow. As a queer identifying POC, they frequently collaborate with groups and organisations involved in social justice and activism, leading and participating in workshops dedicated to opening conversations around consent, mental health and healing through creativity, accountability and community care",
    embedType: 'none',
    embedUrl: '',
  },
  
  
  {
    id: 35,
    name: 'NARNA',
    image: narna_img,
    webpImage: narna_img_webp,
    description: "Leeds based mixed genre DJ and Producer, taking elements from Grime, Jungle, Footwork, Dubstep and DnB. He is on a steady trajectory ripping through the UK rave scene and releases on: Psyked, OTC Recordings, Nazakat, No More Parties, Militant Music, Northside, Deep In The Jungle, Distorted Audio, Beatz On Toast, StepTwice and many more.",
    embedType: 'none',
    embedUrl: '',
  },
  
  {
    id: 39,
    name: 'KLUMZYKHEMIST',
    image: klumsy_img,
    webpImage: klumsy_img_webp,
    description: "Since headlining her first show in the States and returning to the UK in 2019, KlumzyKhemist has been non-stop on making her sound heard. Her reputation for fast-paced, heavy, and unique sets started to buy her attention from some of the most respected events and venues, KlumzyKhemist remains experimental in her music tastes and true to her colours. Well known for her energetic sets filled with chops and double drops, KlumzyKhemist focuses on surprising the crowd as much as possible. Taking inspiration from all genres of music, her style ranges from mixing Neurofunk Drum and Bass, to Dancefloor, Jungle, Footwork, and everything in between, believing in the importance of never sticking to one sound.",
    embedType: 'none',
    embedUrl: '',
  },
  {
    id: 40,
    name: 'MORAL BOMBING',
    image: xiao_img,
    webpImage: xiao_img_webp,
    description: "Music of Dortmund-based emo/powerviolence/grindcore squad Moral Bombing is raw, energetic and emotionally and politically charged. This band has it in spades, combining the most emotive elements of screamo, hardcore and grind, while still finding a way to retain originality. Expect fast, savage and unrelenting powerful sounds with an emotional tint.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/7sS4kf4YifD7wl6otq0c5A?utm_source=generator',
  },
  {
    id: 41,
    name: 'ULTRAS',
    image: moral_bombing_img,
    webpImage: moral_bombing_img_webp,
    description: "Hailing from Oakland, Ultras are a rip roar of relentless, super fast hardcore punk featuring members of World Peace, Fentanyl and more. Signed to Convulse Records, Ultras are a take-no-shit serious fastcore punch to the head, there's no sugarcoated music here, it's a blisteringly raw barrage of thrash, feedback, and vocal shredding power. Come and see for yourself.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2bhHsqSDcaIvnRzsfVKhIK?utm_source=generator',
  },
  
  
  
  {
    id: 49,
    name: 'BLOSSOM DECAY',
    image: blossom_img,
    webpImage: blossom_img_webp,
    description: `Blossom Decay was founded in May 2023.
The sound of the Dresden-based band combines the raw energy of the hardcore underground with a 2000s metalcore revival. Nostalgic melodies and uncompromising mosh parts chart a fresh yet unmistakable course: metalcore for hardcore kids
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/0MXRp2jNEW2ka1E3ewhH8i?utm_source=generator',
  },
  {
    id: 51,
    name: 'CLOBBER',
    image: clobber_img,
    webpImage: clobber_img_webp,
    description: `From playing barber shops in Soho, to tearing apart huge venues with the likes of Sick of it All, Madball, Comeback Kid and more, Clobber are cementing their name as one of the most exciting live acts in heavy music. Injecting 77' style punk with their own brand of explosive modern hardcore.
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/48YxiC97XwVnDlK995fMJ5?utm_source=generator',
  },
  {
    id: 55,
    name: 'OCHRE',
    image: ochre_img,
    webpImage: ochre_img_webp,
    description: `ochre blends a unique style of noise rock, pulling from: shoegaze, emo, hardcore and grunge. This unique style has led to them playing alongside the likes of: Show Me The Body, Midwife, The Bled and Grivo as well as being featured on Amazing Radio and BBC1 Introducing Rock with Alyx Holcombe. 
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/6pfpB4gNxW2xPgynbrbuQN?utm_source=generator',
  },
  {
    id: 57,
    name: 'EMPIRE OF DUST',
    image: empire_img,
    webpImage: empire_img_webp,
    description: `Cataclysmic noise chaos of the bristolian five piece known as 'Empire of Dust'. Throughout every fleshed out power chord, every death cry casted heavenward, and every metronome steady drum smash, Empire of Dust presents an erratic, doom-laden fusion in what can only be described as the fury of a thousand gods. 
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4wYj3PwAu6J2HTp1Y0WHLv?utm_source=generator',
  },
  
  
  
  {
    id: 65,
    name: 'FEENA',
    image: feena_img,
    webpImage: feena_img_webp,
    description: `Born and based in Edinburgh, Feena has made her mark on the city, continuously working to platform local artists through her work as co-director of internet radio station EHFM. This motivation to uplift Scotland in the wider electronic music scene extends throughout her practice, bringing both up & coming and established names in bass music to Edinburgh for club night Miss World at Sneaky Pete's. In the last year, Feena has been on tour across the UK with Scottish label Headset, celebrating their 9th and 10th birthdays, and next year will be playing with the team again at Outlook. This summer she helped facilitate the Scottish debut of Keep Hush with an EHFM takeover, platforming just some of the city's burgeoning talent. She's shared the stage with the likes of Neil Landstrumm, Boofy, Mantra, Eclair Fifi, Jeremy Sylvester, Yushh, Beatrice M., EMA and many more, and recently supported Ben UFO for the Rinse 30th Anniversary party at Sneaky Pete's. Behind the booth, Feena's style is influenced by low-end frequencies and sound system music, crafting webs of futuristic dub and glitched out UK techno alongside gut-punching, hard-driving genre-bending selections. Photo by @Ben.Glasgow
`,
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1890176019&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 67,
    name: 'DJ TURBO BAGUETTE ',
    image: baguette_img,
    webpImage: baguette_img_webp,
    description: `DJ Turbo Baguette is serving up a turbocharged blend of hardtek bangers, cheeky donk drops, and gritty garage grooves to obliterate the dancefloor. With relentless energy and French-flavoured chaos, this set guarantees a wild ride from start to finish—bon appétit for your ears!
`,
    embedType: '',
    embedUrl: '',
  },
  {
    id: 69,
    name: 'BESSA',
    image: bessa_img,
    webpImage: bessa_img_webp,
    description: `Bessa’s Music is a dive head first into a world of live instrumentation and afro-beat rhythms. The Scottish/Algerian artist has been producing and dj’ing for over a decade now with releases on imprints such as ‘Happiness Therapy’, Fatboy Slim’s imprint ‘Southernfried Records’, ‘Red Bull Music’, Glasgow’s ‘Huntleys & Palmers, London’s ‘Stay Cool’, ‘Paradise Palms’ and Bristols ‘Shall Not Fade’ to name a few. Gaining support for his music from the likes of Gilles Peterson, Jamz Supernova and various other tastemakers across BBC radio. As a DJ his set’s are full of afro, arabic, and latin club beats. Having supported a heavy range of artists from the likes of DJ Jazzy Jeff, Skepta, Genius Of Time and Shigeto, and having played various styles of club nights, he has a versatile style and a rich taste which comes out in his sets.
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2wUPZehrSXDGc5Y22p6CLU?utm_source=generator',
  },
  {
    id: 70,
    name: 'FULL PELT',
    image: fullpelt_img,
    webpImage: fullpelt_img_webp,
    description: `Fullpelt will be providing a set spanning the UK bass music spectrum, on a journey through the evolution of the UK sound. A veteran of the Glasgow soundsystem scene, Fullpelt will be pulling some forgotten gems and new bangers to get the whole place moving. Expected loads of UKG, Dubstep, and Bassline.`,
    embedType: '',
    embedUrl: '',
  },
  {
    id: 71,
    name: 'ROKI',
    image: roki_img,
    webpImage: roki_img_webp,
    description: `ROKI is a Glasgow based producer/DJ. Hailing from Croydon, he blends his love of deep bass music with the grooves of Dembow, Dancehall and Gqom. Having produced on projects with corto.alto, Pippa Blundell and DD Dragon, ROKI fuses his own distinctive style across the Scottish jazz, folk and rap scene, and comes to Wastelands festival to get the crowd bouncing!
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/09WKZ1LAoDdpb53Ri5IoqR?utm_source=generator',
  },
  {
    id: 73,
    name: 'BLOOD TOWER',
    image: blood_img,
    webpImage: blood_img_webp,
    description: `Formed in 2016, Blood Tower is a dungeon synth project from Omaha, NE that has released numerous full length albums, splits and EPs over its near decade of existence. Paying tribute to the tales of M.R. James, Clark Ashton Smith and Ambrose Bierce, Blood Tower captures raw, psychedelic horror soundscapes of darkness, dread and debauchery. The frontman also plays with the Omaha hardcore legends BIB. Photo by Peter Beste.
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/1HVoTTYNATi1HmJSWu8x81?utm_source=generator',
  },
  {
    id: 75,
    name: 'RAIVO SLOAN',
    image: raivo_img,
    webpImage: raivo_img_webp,
    description: `
`,
    embedType: 'bandcamp',
    embedUrl: 'https://bandcamp.com/EmbeddedPlayer/album=3402079374/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/',
  },
  {
    id: 76,
    name: 'UM',
    image: um_img,
    webpImage: um_img_webp,
    description: `Um's 6-piece blends experimental rock, folk, driving rhythm, and poetry to create a compelling racket. Since arriving on the experimental scene in Glasgow, the band’s been a totally fresh breath of air, encompassing a sound that truly stands alone, having to be seen live to be believed. 
`,
    embedType: '',
    embedUrl: '',
  },
  {
    id: 77,
    name: 'GIMIC',
    image: gimic_img,
    webpImage: gimic_img_webp,
    description: `Frenetic and melodic hardcore punk from Bristol. Inspired by political DIY punk from all eras... think Dischord Records, Dead Kennedys, Subhumans, etc. Music that’ll make your head `,
    embedType: 'bandcamp',
    embedUrl: 'https://bandcamp.com/EmbeddedPlayer/album=833241355/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true',
  },
  {
    id: 78,
    name: 'OVERPOWER',
    image: overpower_img,
    webpImage: overpower_img_webp,
    description: `Since forming in 2019, OVERPOWER have become a dominant force within the UK's thriving metal and hardcore scene, due to their explosive live shows and ruthless determination to make their name synonymous with all things thrash. With tours and shows in Europe, an appearance at 2000 Trees and Burn it Down festival and a UK tour with Grove Street in 2024, OVERPOWER are looking to make 2025 the year everybody knows their name…
"Crossover thrash is their main game , as proven by heart-racing tempos and shredding solos. expect big things from Bristol’s OVERPOWER" - No Echo
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/1JXEWwmUx8cvcUfRCV6i0W?utm_source=generator',
  },
  {
    id: 79,
    name: 'AL GU',
    image: algu_img,
    webpImage: algu_img_webp,
    description: `Al Gu is a DJ/Producer, mainly recognised for their Goop EP, as well as their involvement in music related Community Building Projects throughout Scotland: Faerie Circle and Slàinte Mhath and their one off event with baile/baile - Na Gabh Dragh (a club night with an included Gaelic lesson at Summerhall). Starting their career curating and producing collage works for the exhibition Finding contentment at Bridgend Farmhouse, Al expanded to nightlife events, DJ-ing and sound production. Their music has been a part of Your Tears Taste like Sugar at Petersburg Art Space, Works in Progress on EHFM and short film Waiting for Marcus. They also have a regular EHFM show ratarsed with Faerie Circle Co-creator Malicedeejay, showcasing oddball selections and excellent yapping. Expect heavy bass, jungle/breakbeats and electro from Al’s genre-bending DJ sets. 
`,
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1899823627&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  // {
  //   id: 81,
  //   name: 'DIVINE HATRED',
  //   image: divine_img,
  //   webpImage: divine_img_webp,
  //   description: `Divine Hatred are straight up hardcore/death metal from Sheffield. Looking for something savage? Feast your ears. No nonsense music to make your body move, enough said.
  //   `,
  //   embedType: 'spotify',
  //   embedUrl: 'https://open.spotify.com/embed/artist/4HJutnkL3HRk5Ta6mUwpJV?utm_source=generator',
  // },
  {
    id: 83,
    name: 'MONI JITCHELL',
    image: moni_img,
    webpImage: moni_img_webp,
    description: `Moni Jitchell are an Experimental-Hardcore Backing Track 2 piece from Glasgow (FFO The Armed, Melt Banana and Botch).
Since their first show in December 2021 they have released their debut EP 'Clear' and LP 'UNREAL', have played shows all over the UK, headlined a stage at the first Core festival in Glasgow and supported the likes of: Mclusky, Unsane, Melt Banana, Part Chimp, Greg Puciato, '68, Iceage, MSPaint, Benefits and Deaf Club. 
To mark the 1st year of their debut LP 'UNREAL' being released, the band put on a show in May 2024 and played the record in full with a 12 piece band - with live footage and a recording of the one off show in the works. Expect them to release new music and tour throughout 2025.  
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4xYqX68iBLZX02wou3wYnP?utm_source=generator',
  },
  
  {
    id: 91,
    name: 'MIIRA',
    image: miira_img,
    webpImage: miira_img_webp,
    description: `Miira is a multi genre DJ based in Edinburgh. They hold down residencies both at Miss World and EHFM with their radio show Warm Up, which strives to platform underrepresented DJs in the Scottish scene. They have supported names like Eich, Sempra, 4am Kru and Honey Drip amongst others and performed at Kelburn Garden Party this year. miira’s eclectic sets are characterised by heavy basslines and percussive rhythms. Their sets traverse the spectrum of dance music, from shuddering 140 to hefty breaks, no set of theirs is the same but is guaranteed to get your feet moving.
`,
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1470539134&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 95,
    name: 'LINK CITY',
    image: link_img,
    webpImage: link_img_webp,
    description: `With a knack for blending classic and contemporary sounds,
Link City transforms any space into a pulsating dance floor. Renowned for his exceptional versatility, Link City has become a trailblazer in the Edinburgh fast music scene. His ability to seamlessly blend tracks across genres has set him apart, transforming each performance into a sonic journey that captivates audiences. His impact on Edinburgh's music scene has been nothing short of impactful, earning him recognition for his unmatched energy and groundbreaking approach. Beyond the turntables, he curates a space for fast paced music like no other. With a knack for blending classic and contemporary sounds, Link City transforms any
space into a pulsating dance floor.
`,
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1851114882&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 97,
    name: 'WREAKING JOY',
    image: wreaking_img,
    webpImage: wreaking_img_webp,
    description: `Wreaking Joy are a 5 piece posi-PV/HC band from Scotland. Causing chaos and mischief whilst having fun! We scream about hamsters, Paul Rudd, as well as more serious topics such as toxic masculinity, discrimination and violence. Wreaking Joy aim to spread positivity in the hardcore scene and to have fun!
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/3Nm5pM6JnAM9psXdOIcpbN?utm_source=generator',
  },
  {
    id: 99,
    name: 'SCOOP',
    image: scoop_img,
    webpImage: scoop_img_webp,
    description: `Hardcore punk thundersword SCOOP strikes straight from Prague. The band combines fast, fierce punk riffs, stomping medieval d-beats, and a loud vocal performance. Over just over a year, the band has been getting more attention with its fresh and fun attitude.
`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/0fuQasEb129YQXFrcDOtLL?utm_source=generator',
  },
  {
    id: 101,
    name: 'HEVER',
    image: hever_img,
    webpImage: hever_img_webp,
    description: `Hever are revitalizing old skool groove hardcore and they do it with particular passion for the genre and for the playing itself. Despite being heavy and fairly brutal on stage, their music and performances are filled with utmost joy and love. Guitar sound is quite inspired by the early Cro-Mags / Pantera / Dinosaur Jr, backed by brain formatting cromagnonic rhythm section and preacher-like pissed off vocals with roots in 80's new york hardcore. Lyrics as expected bear revolting and anti-capitalist / anti-system messages.`,
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4HQVPkji2rZcS8ZuoFKiFT?utm_source=generator',
  }

];

const Lineup = () => {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const popupRef = useRef(null);

  // Function to open the popup/modal with artist details
  const handleArtistClick = (artist) => {
    setSelectedArtist(artist);
  };

  // Function to close the popup/modal
  const closePopup = () => {
    setSelectedArtist(null);
  };

  // Close popup when clicking outside of the popup content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    if (selectedArtist) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedArtist]);

  // Render the correct iframe based on embed type
  const renderEmbed = (artist) => {
    switch (artist.embedType) {
      case 'spotify':
        return (
          <iframe
            src={artist.embedUrl}
            width="100%"
            height="152"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            title="Spotify Player"
          ></iframe>
        );
      case 'youtube':
        return (
          <iframe
            width="100%"
            height="315"
            src={artist.embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Player"
          ></iframe>
        );
      case 'vimeo':
        return (
          <iframe
            src={artist.embedUrl}
            width="100%"
            height="315"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo Player"
          ></iframe>
        );
      case 'soundcloud':
        return (
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src={artist.embedUrl}
            title="SoundCloud Player"
          ></iframe>
        );
        case 'bandcamp':
          return (
            <iframe
              style={{ border: 0, width: '100%', height: '44px' }}
              src={artist.embedUrl}
              seamless
              title="Bandcamp Player"
            ></iframe>
          );
      default:
        return null;
    }
  };

  return (
    <>
    <Helmet>
        <title>Lineup - Wastelands Festival 2025</title>
        <meta name="description" content="Lineup of bands, dj's and artists for Wastelands Festival 2025." />
      </Helmet>
    <section className="lineup-page" id="lineup">
      <Blobs />
      <div className="lineup-container">
        <h1>Lineup</h1>
        <div className="artist-grid">
          {artistData.map((artist) => (
            <div key={artist.id} className="artist-card" onClick={() => handleArtistClick(artist)}>
              <div className="artist-card-container">
                {/* Use <picture> element for WebP and fallback */}
                <picture>
                  {/* WebP image */}
                  <source srcSet={artist.webpImage} type="image/webp" />
                  {/* Fallback to original image (optional) */}
                  <img src={artist.image} alt={artist.name} className="artist-image" />
                </picture>
              </div>
              <p className="artist-name">{artist.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Modal/Popup for showing artist details */}
      {selectedArtist && (
        <div className="artist-popup">
          <div className="popup-content" ref={popupRef}>
            <div className="button-container">
              <button className="close-button courier" onClick={closePopup}>
                close &times;
              </button>
            </div>

            {/* Display the WebP image directly without conversion */}
            <picture>
              <source srcSet={selectedArtist.webpImage} type="image/webp" />
              <img src={selectedArtist.image} alt={selectedArtist.name} className="popup-image" />
            </picture>

            <h2>{selectedArtist.name}</h2>
            <p className="courier">{selectedArtist.description}</p>
            <div className="media-embed">
              {renderEmbed(selectedArtist)}
            </div>
          </div>
        </div>
      )}
    </section>
    </>
  );
};

export default Lineup;