import React from 'react';
import { Helmet } from 'react-helmet'; // For dynamic meta tags
import '../css/VenuesPage.css';
import Blobs from '../Components/Blobs';

import baad from '../img/venue_img/baad.jpeg'
import room from '../img/venue_img/room.jpg'
import slay from '../img/venue_img/slay.jpeg'
import stereo from '../img/venue_img/stereo.webp'

const venues = [
  {
    name: "BAaD",
    accent: "Venue & Box Office",
    description: "BAaD is a staple venue in the heart of the vibrant Glasgow Barrowlands. Enjoy market stalls by locally based artists. ",
    descriptionLine: "Please do take some time to have a look at all these stalls and support the wonderful Glasgow DIY scene.",
    descriptionLineTwo: "We are in partnership with Thistly Cross cider and Tempest Brewery to deliver the tastiest beverages this side of planet earth. Under 18's must be accompanied by an adult.",
    image: baad,
    mapsLink: "https://maps.app.goo.gl/W1CbpGNrtopALSy19",
    address: '54 Calton Entry'
  },
  {
    name: "Room 2",
    accent: "Venue",
    description: "Opened in 2019 as a multipurpose venue, Room 2 caters for everything from electric live music events to proper club nights.",
    descriptionLine: "Boasting a finely-tuned Funktion-One soundsystem and an incredible lighting rig, this is one for the heads.",
    descriptionLineTwo: "Unfortunately, we cannot allow under 18's into Room 2.",
    image: room,
    mapsLink: "https://maps.app.goo.gl/ARhuC4XamApe1bY38",
    address: '69 Nelson Mandela Place'
  },
  {
    name: "SLAY",
    accent: "Venue",
    description: "Located in the heart of Glasgow, Slay is a popular live music venue renowned for its diverse lineup of acts and energetic atmosphere.",
    descriptionLine: "We are in partnership with Thistly Cross cider and Tempest Brewery to deliver the tastiest beverages this side of planet earth.",
    descriptionLineTwo: "Unfortunately, we cannot allow under 18's into Slay.",
    image: slay,
    mapsLink: "https://maps.app.goo.gl/NDJ1VcHzoKPGcwsB9",
    address: '24 Glassford Street'
  },
  {
    name: "Stereo",
    accent: "Venue",
    description: "Stereo has been around in Glasow's city centre since 2007, hosting an endless variety of gigs, live performances and clubs through to the early morning.",
    descriptionLine: "A dedicated vegan bar & kitchen by day, and into the dusty evenings, you'll be amazed by the unbeatable menu, cold beers, and varied selection of boozy delights.",
    descriptionLineTwo: "Unfortunately, we cannot allow under 18's into Stereo.",
    image: stereo,
    mapsLink: "https://maps.app.goo.gl/mveS7nn7VUDjBmiB6",
    address: '22 Renfield Lane'
  }
];

const VenuesPage = () => {
  return (
    <>
      <Helmet>
        <title>Venues - Wastelands Festival 2025</title>
        <meta name="description" content="Partner clubs and venues hosting Wasteland Festival 2025." />
      </Helmet>

      <section className="venues-page">
        <React.Suspense fallback={<div>Loading...</div>}>
          <Blobs />
        </React.Suspense>

        <div className='venues-container'>
          <div className="venues-header">
            <h1>Our Venues</h1>
            <h2>Explore our partner venues</h2>
          </div>

          <div className="venues-info">
            <h4 className="courier">Wastelands Festival is hosted across four of Glasgow's most iconic venues, offering a selection of gig and club venues, locally-based artists, and Scottish breweries.</h4>
          </div>

          <div className="venues-list-container">
            {venues.map((venue, index) => (
              <div key={venue.name} className={`venue ${index % 2 === 0 ? 'image-left' : 'image-right'}`}>
                <img src={venue.image} alt={venue.name} className="venue-image" />
                <div className="venue-text">
                  <h4 className="venue-accent">{venue.accent}</h4>
                  <h1 className='venue-title'>{venue.name}</h1>
                  <h4 className='venue-maps-link'>
                    <a href={venue.mapsLink} className='inline-link' target='_blank' rel="noreferrer">{venue.address}</a>
                  </h4>
                  <div className='venue-description-container'>
                    <p className="courier">{venue.description}</p>
                    <p className="courier">{venue.descriptionLine}</p>
                    <p className="courier">{venue.descriptionLineTwo}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="venue-link-container">
            <a 
              href="https://ra.co/events/2115648" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="ticket-link"
              aria-label="Buy tickets for Wastelands Festival 2025 on Resident Advisor"
            >
              Buy Tickets
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default VenuesPage;
