import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Add react-helmet for dynamic meta tags
import '../css/contact.css';
import Blobs from '../Components/Blobs';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Google Apps Script Web App URL
    const scriptURL = 'https://script.google.com/macros/s/AKfycbxxyzZddno0oN5Xh6jIcwCd9QAjghL7hHNDMksiOZFL9JZ_8qUHSeI_FPzmMhfFQB9JRA/exec';

    fetch(scriptURL, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setStatus('Message sent successfully!'))
      .catch(() => setStatus('Failed to send message.'));

    // Optionally reset form
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Wastelands Festival</title>
        <meta name="description" content="Contact Wastelands Festival for any queries. Email us for ticketing and booking details." />
        <meta name="keywords" content="Wastelands Festival, contact, festival booking, festival tickets" />
        <meta property="og:title" content="Contact Us - Wastelands Festival" />
        <meta property="og:description" content="Contact Wastelands Festival for any ticket and booking enquiries." />
      </Helmet>

      <section className="contact-page" id="contact">
        {/* Lazy-load the Blobs component */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <Blobs />
        </React.Suspense>

        <div className='contact-container'>
          <h1>Contact Us</h1>

          <div className='contact-form-container'>
            <div className="contact-text-container">
              <h3 className="courier">
                Please email <a className="courier inline-link" href="mailto:enquiries@wastelandsfestival.co.uk">enquiries@wastelandsfestival.co.uk</a> with any ticket queries.
              </h3>
              <p className="courier">
                For all booking enquiries please email <a className="courier inline-link" href="mailto:tam@wastelandsfestival.co.uk">tam@wastelandsfestival.co.uk</a>.
              </p>
            </div>

            <div className='form-container'>
              <form onSubmit={handleSubmit} aria-label="Contact Form">
                <div className='form-field'>
                  <label htmlFor="name" className="courier">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    aria-label="Your name"
                    placeholder="Enter your name"
                    className="courier"
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor="email" className="courier">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    aria-label="Your email"
                    placeholder="Enter your email"
                    className="courier"
                  />
                </div>

                <div className='form-field'>
                  <label htmlFor="message" className="courier">Message:</label>
                  <textarea
                  className="courier"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    aria-label="Your message"
                    placeholder="Enter your message"
                  />
                </div>

                <button type="submit">Send</button>
              </form>
              {status && <p>{status}</p>}
            </div>
          </div>
        </div>

        {/* Adding structured data using JSON-LD for SEO */}
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "ContactPage",
                "name": "Contact Us - Wastelands Festival",
                "description": "Contact Wastelands Festival for any ticket or booking enquiries.",
                "contactPoint": [{
                  "@type": "ContactPoint",
                  "email": "enquiries@wastelandsfestival.co.uk",
                  "contactType": "Customer Support",
                  "areaServed": "GB"
                },{
                  "@type": "ContactPoint",
                  "email": "tam@wastelandsfestival.co.uk",
                  "contactType": "Booking Inquiries",
                  "areaServed": "GB"
                }]
              }
            `}
          </script>
        </Helmet>
      </section>
    </>
  );
};

export default Contact;
